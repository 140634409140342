import { Link, PageProps } from 'gatsby';
import React from 'react';
import { StepStarter } from '../models/ContentfulTypes';
import {
  TeliaRow,
  TeliaP,
  TeliaHeading,
  TeliaButton,
} from '@teliads/components/react';
import { Layout, Grid } from '../components/Layout';
import '../styles/divider.scss';

interface Props extends PageProps {
  pageContext: {
    starterPage: StepStarter;
    deviceSlug: string;
    nextPageUrl: string;
  };
}

export default ({
  pageContext: { starterPage, deviceSlug, nextPageUrl },
}: Props) => {
  return (
    <Layout
      variant="secondary"
      contentfulId={starterPage.contentful_id}
      deviceSlug={deviceSlug.split('/').slice(-1).join('')}
      title={starterPage.title}
    >
      <Grid>
        <TeliaRow className="column gap center padding--vertical divider--message">
          <TeliaHeading tag="h3" variant="display-100">
            {starterPage?.title}
          </TeliaHeading>
          <TeliaP>{starterPage?.pageDescription?.pageDescription}</TeliaP>
          <div>
            <img
              src={starterPage?.pageImage?.file?.url}
              alt={starterPage?.title}
            />
          </div>
          <Link to={nextPageUrl}>
            <TeliaButton
              variant="primary"
              text={starterPage.navigationButtonText}
            />
          </Link>
        </TeliaRow>
      </Grid>
    </Layout>
  );
};
